import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const MainPage = () => {
  return <Container>
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Zapněte si sluneční energii!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Každý rok dopadá na vaše volné plochy obrovské množství sluneční energie.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" component={Link} to={'/nabidka'}>Chci ji využít</Button>
          <Button variant="outlined">Chci o tom vědět víc</Button>
        </Stack>
      </Container>
    </Box>
  </Container>
}

export default MainPage;