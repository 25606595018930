import React from 'react';
import ReactToPdf from "react-to-pdf";
import { makeStyles } from '@mui/styles';

const ref = React.createRef();

const useStyles = makeStyles({
  "body": {
    "margin": "0",
    "padding": "0",
    "backgroundColor": "#FAFAFA",
    "font": "12pt \"Tahoma\""
  },
  "": {
    "boxSizing": "border-box",
    "MozBoxSizing": "border-box"
  },
  "page": {
    "margin": "0",
    "border": "initial",
    "borderRadius": "initial",
    "width": "8.3in",
    "minHeight": "11.7in",
    "boxShadow": "initial",
    "background": "initial",
    "pageBreakAfter": "always",
    "padding": "2cm",
  },
  "subpage": {
    "padding": "1cm",
    "border": "5px red solid",
    "height": "237mm",
    "outline": "2cm #FFEAEA solid"
  },
});

const options = {
  orientation: 'portrait',
  unit: 'in',
  format: [8.3, 11.7]
};

const Contracts = () => {
  const classes = useStyles();
  return <div>
    <ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options} scale={.8}>
      {({ toPdf }) => (
        <button onClick={toPdf}>Generate pdf</button>
      )}
    </ReactToPdf>

    <div className={classes.book} ref={ref}>

      <div className={classes.page} >
        <div className={classes.subpage}>Page 1/2</div>
      </div>
      <div className={classes.page}>
        <div className={classes.subpage}>Page 2/2</div>
      </div>
    </div></div>;
}

export default Contracts;