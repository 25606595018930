import React from 'react';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';

const useStyles = makeStyles(theme => ({
  containerPadding: {
    paddingBottom: '4em',
  },
  subtitle: {
    color: grey[600],
    marginLeft: '1em'
  },
}));

const ActionAreaCard = (props) => {
  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardActionArea>
        <props.icon color="secondary" fontSize="large" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.children}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            9,9 kWp + 11.6 kWh AKU
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


const Offer = () => {
  const classes = useStyles();

  return <Box m={2} pt={3}>
    <Container className={classes.containerPadding}>
      <Typography variant="h2" component="h3">U nás si vyberete</Typography>
      <Typography variant="subtitle1" gutterBottom component="div" className={classes.subtitle}>a poté řešení upravíme na míru.</Typography>
    </Container>
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 3, sm: 2, md: 4 }}
      justifyContent="center"
      alignItems="center"
    >
      <ActionAreaCard icon={BatteryChargingFullIcon}>SolarON Max</ActionAreaCard>
      <ActionAreaCard icon={BatteryCharging90Icon}>SolarON Midi</ActionAreaCard>
      <ActionAreaCard icon={BatteryCharging60Icon}>SolarON Mini</ActionAreaCard>
    </Stack></Box>;
}

export default Offer;