import { createTheme } from '@mui/material/styles';
import { orange, purple } from '@mui/material/colors';

export default createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: orange[500],
    },
  },
});