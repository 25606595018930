import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  subtitle: {
    maxWidth: '30em'
  },
}));

const WhoWeAre = () => {
  const classes = useStyles();
  return <Container>
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="sx">
        <Typography
          component="h1"
          variant="h2"
          align="left"
          color="text.primary"
          gutterBottom
        >
          Fotovoltaika je komplexní řemeslo
        </Typography>
        <Typography variant="h5" align="flex-start" color="text.secondary" paragraph className={classes.subtitle}>
          Jsme skupina zkušených řemeslníků sdílejících své know-how. Spolu tvoříme dodavatele fotovoltaických řešení.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="center"
        >
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="foto"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Projektanti FVE
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Kvalitní dokumentace je základ pro každý projekt.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="foto"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Výškové práce
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Naše instalace realizují řemeslníci kvalifikovaní na výškové práce.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="foto"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Elektromontéři
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Zapojení systému provádí zkušení elektromontéři s kvalifikací pro montáže fotovoltaických systémů.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="foto"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Revizní technici
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Po instalaci následuje výchozí revize systému nutná k připojení k síti.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="140"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="foto"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Administrativní pracovníci
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vzhledem ke složitosti administrativy na poli fotovoltaiky, administrace připojení a dotačních titulů máme na tuto činnost specializované pracovníky. Díky tomu můžeme dodávat FVE na klíč.
              </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </Box>
  </Container>
}

export default WhoWeAre;