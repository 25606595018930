import Header from './ui/Header/Header';
import Footer from './ui/Footer/Footer';
import theme from './ui/Theme';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  Routes,
  Route
} from "react-router-dom";
import MainPage from './ui/MainPage/MainPage';
import Offer from './ui/Offer/Offer';
import WhoWeAre from './ui/WhoWeAre/WhoWeAre';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="nabidka" element={<Offer />} />
          <Route path="kdo_jsme" element={<WhoWeAre />} />
        </Routes>

        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
